import { getStatusCode, handlerErrorEcommerce } from '~/plugins/error-handler'
import updateOrderData from '~/plugins/update-order-data'
import updateCustomerAccount from '~/plugins/update-customer-account'
import updateEcommerceData from '~/plugins/update-ecommerce-data'
import getStepFromPath from '~/utils/get-step-from-path'
import Cookies from 'js-cookie'
import { COMFINO_SID_KEY, EVENTS } from '~/utils/consts'
import updateCustomerData from '~/plugins/update-customer-data'

async function updateEcommerceStoreData ($axios, store, token) {
    const storeResponse = await $axios.get(`${process.env.API_ECOMMERCE_ORIGIN}/store/${token}`)

    store.commit('ecommerce/setStore', storeResponse.data)
}

async function updateData ($axios, $accountAxios, store, token, $popup) {
    await Promise.all([
        updateCustomerData($axios, store, token),
        updateEcommerceData($axios, store, token),
    ])
    await updateCustomerAccount($accountAxios, store, $popup)
}

async function updateBackendDataLayer ($axios, store, token, $analytics) {
    try {
        const response = await $axios.get(`/orders/${token}/data-layer`, {
            validateStatus: function (status) {
                return status === 200 || status === 401
            },
        })

        const backendDataLayer = {}
        Object.entries(response.data).forEach(([key, value]) => {
            backendDataLayer[`backend_${key}`] = value
        })
        store.commit('analytics/setBackendDataLayer', backendDataLayer)

        $analytics(EVENTS.PAGE_LOADED)
    } catch (error) {
        console.error(error)
    }
}

export async function getSession ($accountAxios, token) {
    let comfinoSid = Cookies.get(COMFINO_SID_KEY)
    if (!comfinoSid) {
        comfinoSid = await initialiseSession($accountAxios, token)
    }
    return comfinoSid
}
export async function initialiseSession ($accountAxios, token) {
    const response = await $accountAxios.post(`init-session/${token}`)
    const comfinoSid = response.data[COMFINO_SID_KEY]
    saveSession(comfinoSid, token)

    return comfinoSid
}
function saveSession (comfinoSid, token) {
    const URLObject = new URL(window.location)
    const cookieDomain = URLObject.host.replace('wniosek', '')
    Cookies.set(COMFINO_SID_KEY, comfinoSid, { domain: cookieDomain, sameSite: 'strict', expires: 1 })
}

export default async function ({ app, route, store, $axios, $accountAxios, $popup, redirect, $sentry, $analytics }) {
    const currentStep = getStepFromPath(route.path)

    const { token } = route.query
    if (!token) {
        throw Error('No query token')
    }

    await updateEcommerceStoreData($axios, store, token)
    await updateOrderData($axios, store, token)
    await getSession($accountAxios, token)

    const stepResponse = await $axios.get(`/orders/${token}/step`)
    const flowStep = stepResponse.data

    if (!route.path.includes(`${flowStep.layout}/${flowStep.step}`)) {
        return redirect(`/${flowStep.layout}/${flowStep.step}?token=${token}`)
    }

    store.commit('setFlowStep', flowStep)
    await updateBackendDataLayer($axios, store, token, $analytics)

    if (['verify', 'logowanie'].includes(flowStep.step)) {
        return
    }

    if (!store.state.firstLoad) {
        return
    }

    try {
        await updateData($axios, $accountAxios, store, token, $popup)
        store.commit('setFirstLoad', false)
    } catch (error) {
        await handlerErrorEcommerce($sentry, app.router, $axios, route, $popup, error, {
            title: 'Nie udało się pobrać danych Twojego wniosku.',
            text: 'Upewnij się, że otworzony został właściwy link. Nie przejmuj się, jeśli problem będzie się powtarzał - nasz konsultant skontaktuje się z Tobą i pomoże Ci dokończyć wniosek.',
        })
    }
}
